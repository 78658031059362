import styled from 'styled-components'

const TermsTitle = styled.h1`
  background: #fff;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.secondary};
  padding: 15px 10px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 25px;
    line-height: 30px;
    padding: 30px 10px 40px 10px;
  }
`

export default TermsTitle
